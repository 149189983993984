<template>
    <div class="person">
        <OrgTree :orgList="orgList" v-model="params.orgId" :onlyShowCourt="true">
            <template slot="person">
                <div class="all" :class="{ 'active': params.orgId === '' }" @click="params.orgId = ''">全部人员</div>
            </template>
        </OrgTree>
        <div class="right">
            <div class="tabs-container">
                <Tabs v-model="params.status" :data="typeList"></Tabs>
                <div class="tabs-right">
                    <span v-if="total">{{ `全部人员(${total})人` }}</span>
                    <button class="btn submit-btn" @click="exportClick" v-permission="['/hrms/user/search-page']">导出</button> 
                </div>
            </div>
            <div class="content-container">                                        
                <div class="search-container">
                    <Search :params="params" :paramsHeaders="paramsHeaders" :getTableData="getTableData"
                        ignoreKeys="status">
                        <template slot="updateTime">
                            <el-date-picker style="width: 350px;" size="small" v-model="startTimeValue" type="datetimerange"
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
                                :default-time="['00:00:00', '23:59:59']"
                                @change="startTimeValueChange" clearable>
                            </el-date-picker>
                        </template>
                    </Search>
                </div>
                <div class="table-container">
                    <CommonTable :headers="headers" :tableData="tableData"   :selection="true" :selectList.sync="selectList" 
                        :loading="loading" :hasIndex="true" :maxHeight="620">
                        <template slot="sex" slot-scope="{ row }">
                            {{ EnumType.sex[row.sex] }}
                        </template>
                        <template slot="education" slot-scope="{ row }">
                            {{ EnumType.education[row.qualification] }}
                        </template>
                        <template slot="status" slot-scope="{ row }">
                            {{ EnumType.userStatus[row.status] }}
                        </template>
                        <template slot="operate" slot-scope="{ row }">
                            <div class="operate">
                                <el-button type="text" size="small" @click="detailClick(row)">查看</el-button>
                            </div>
                        </template>
                    </CommonTable>
                </div>
                <div class="footer">
                    <el-button size="small" type="danger" @click="delExamine" v-permission="['/hrms/user/remove']"
                        :disabled="selectList.length === 0">删除</el-button>
                    <div class="pagination">
                
                        <span>共{{total}}条</span>
                        <el-pagination background layout="sizes,prev, pager, next" :current-page.sync="params.current"
                            :page-sizes="[10, 30, 50, 100]" :page-size.sync="params.size" :total="total">
                        </el-pagination>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { getOrgList, userList, getJobList, delUser } from '@/api/hrAPI.js';
import EasyTree from '@/common/easyTree';
import Tabs from '@/components/tabs';
import OrgTree from '@/components/orgTree';

export default {
	name: 'person',
    components: {
        EasyTree,
        Tabs,
        OrgTree,
    },
    data () {
        return {
            orgList: [],
            paramsHeaders: [
                {
                    label: '更新时间',
                    slot: 'updateTime'
                },
                {
                    label: '年龄',
                    value: 'age',
                    type: 'select',
                    typeList: [
                        {
                            label: '35岁以下',
                            value: '0-35'
                        },
                        {
                            label: '36-40岁',
                            value: '36-40'
                        },
                        {
                            label: '41-45岁',
                            value: '41-45'
                        },
                        {
                            label: '46-50岁',
                            value: '46-50'
                        },
                        {
                            label: '51-55岁',
                            value: '51-55'
                        },
                        {
                            label: '56-60岁',
                            value: '56-60'
                        },
                        {
                            label: '61岁以上',
                            value: '61-150'
                        },
                    ]
                },
				{
					label: '工作年限',
                    value: 'workYear',
                    type: 'select',
                    typeList: [
                        {
                            label: '2年以下',
                            value: '0-1'
                        },
                        {
                            label: '2-4年',
                            value: '2-3'
                        },
                        {
                            label: '4-6年',
                            value: '4-5'
                        },
                        {
                            label: '6-8年',
                            value: '6-7'
                        },
                        {
                            label: '8-10年',
                            value: '8-9'
                        },
                        {
                            label: '10年以上',
                            value: '10'
                        }
                    ]
				},
                {
                    label: '学历',
                    value: 'qualification',
                    type: 'select',
                    typeList: this.EnumList.educationList
                },
                {
                    label: '职务',
                    value: 'positionId',
                    type: 'select',
                    typeList: []
                },
                {
                    label: this.$getCurrentLib() ? '部门' : '单位',
                    value: 'orgName',
					width: 215
                },
                {
                    label: '姓名',
                    value: 'userName'
                },
            ],
            params: this.createParams(),
            total: 0,
            startTimeValue: [],
            headers: [
                {
                    label: '姓名',
                    value: 'name'
                },
                {
                    label: '性别',
                    slot: 'sex'
                },
                {
                    label: '年龄',
                    value: 'age'
                },
                {
                    label: '工作年限',
                    value: 'workYear'
                },
                {
                    label: '学历',
                    slot: 'education'
                },
                {
                    label: '成员状态',
                    slot: 'status'
                },
                {
                    label: this.$getCurrentLib() ? '部门' : '单位',
                    value: 'orgName'
                },
                {
                    label: this.$getCurrentLib() ? '现任职务' : '职务',
                    value: 'currentPositionNames'
                },
                {
                    label: '更新时间',
                    value: 'updateTime'
                },
                {
                    label: '操作',
                    slot: 'operate'
                }
            ],
            tableData: [],
            selectList: [],
            loading: false,
        }
    },
    watch: {
        'params.status': {      //  顶部选项参数
            handler (val) {
                this.getTableData();
            }
        },
        'params.orgId': {       //  左侧树
            handler (val) {
                this.getTableData();
            }
        },
        'params.startTime': {
            handler (val) {
                if (!val) {
                    this.startTimeValue = [];
                }
            }
        },
    },
    computed: {
        typeList () {
			// const target = this.EnumList.userStatusList.find(item => item.value === 'DEPART')
			// if(target) {
			// 	target.label = this.$replaceText('辞职')
			// }
            return [
                {
                    label: '全部',
                    value: ''
                },
                ...this.EnumList.userStatusList
            ]
        },
        contrastUserList () {
            return this.$store.getters.contrastUserList;
        }
    },
	async beforeRouteEnter (to, from, next) {
		// console.log(to, from);
		// console.log('beforeRouteEnter');
		const res = await getJobList()
		if(from?.name?.startsWith('home')) {
			next(vm => {
				vm.setJobList(res)
				vm.setParams()
				// console.log("Home");
			})
		} else if(from?.name?.startsWith('userDetail')) {
			next(vm => {
				const params = {}
				const numKeys = ['current', 'size']
				Object.keys(to.query).forEach(key => {
					const value = to.query[key]
					if(value) {
						params[key] = numKeys.includes(key) ? +value : value
					}
				})

				vm.setJobList(res)
				vm.params = {...vm.params, ...params} 
			})
		} else {
			next(vm => vm.setJobList(res))
		}
	},
	created() {},
    updated(){},
    methods: {
		createParams() {
			return {
                current: 1,
                size: 30,
                orgId: '',
                orgName: '',
                age: '',
                positionId: '',
                startTime: '',
                endTime: '',
                userName: '',
                status: ''
            }
		},
		setParams() {
			const {key, label, orgId} = this.$route.query
			if(orgId) {
				this.params.orgId = orgId
			} 
			if(key && label) {
				const typeList = this.paramsHeaders.find(h => h.value === key)?.typeList
				if(typeList) {
					const target = typeList.find(t => t.label === label)
					if(target) {
						this.params[key] = target.value
						this.params.status = 'BE_ON_THE_JOB'
					}
				}
			}

			delete this.params['key']
			delete this.params['label']
		},
		setJobList(res = []) {
			this.paramsHeaders.find(item => item.value === 'positionId').typeList = res.map(item => {
                return {
                    label: item.name,
                   	value: item.id,
                }
            })
		},
        getOrgList () {
            getOrgList().then(res => {
                this.orgList = res;
            })
        },
        getTableData () {
            this.loading = true;
            return userList(this.params).then(res => {
                this.tableData = res.records;
                this.total = res.total;
            }).finally(() => {
                this.loading = false;
            })
        },
        startTimeValueChange (val) {
            if(val){
                this.params.startTime = val[0];
                this.params.endTime = val[1];
            }else{
                this.params.startTime = '';
                this.params.endTime = '';
            }
        },
        filterNode (val, data) {
            return data.name.includes(val);
        },
        detailClick (row) {
			const params = {}
			Object.keys(this.params).forEach(key => {
				if(this.params[key]) {
					params[key] = this.params[key]
				}
			})
            this.$router.push({
                path: '/user-detail',
                query: {
					...params,
                    userId: row.id,
                },
            })
        },
        delExamine1() {
            const ids = this.selectList.map(item => item.id).join(',')
            this.$confirm('确定要删除用户么', '确认删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
				const path = '/hrms/user/remove'
				const names = this.selectList.map(item => item.name)
		  		const note = `删除用户${names.join(',')}`
		  		this.$store.commit('status/SET_LOG', {path, note})
                delUser({ ids }).then(res => {
                    this.$message.success('删除成功');
                    this.getTableData();
                })
            }).catch(err => {
                console.log(err);
            });
        },
		async delExamine() {
            await this.$confirm('确定要删除用户么', '确认删除', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
			for(const user of this.selectList) {
				const {id, name} = user
				const path = '/hrms/user/remove'
		  		const note = `删除用户${name}`
				this.$store.commit('status/SET_LOG', {path, note})
				await delUser({ ids: id })
			}
            this.$message.success('删除成功');
			this.getTableData();
		},
        exportClick () {
            const params = {
                ...this.params,
                export: 'excel',
                fileName: '123.xlsx'
            }
            userList(params).then(res => {

            })
        }
    },
    mounted() {
		// console.log('mounted');
		this.getOrgList();
		this.getTableData();
	}
}
</script>

<style lang="less" scoped>
.person {
    padding: 2px 0;
    display: flex;
    justify-content: space-between;
    .right {
        width: 0;
        height: 100%;
        flex-grow: 1;
        position: relative;

        .tabs-container {
            margin: 16px 0;
            display: flex;
            justify-content: space-between;

            .tabs-right {
                padding-right: 20px;

                >span {
                    margin-right: 35px;
                }
            }
        }

        .content-container {
            width: 100%;
            height: calc(100% - 66px);
            background-color: #fff;
            padding: 20px;
            box-sizing: border-box;

            .search-container {
                /deep/ .el-input {
                    width: 120px;
                }

                /deep/ .el-select {
                    width: 140px;

                    .el-input {
                        width: 100%;
                    }
                }
            }

            .table-container {
                margin-top: 16px;
            }

            .footer {
                position: absolute;
                left: 20px;
                right: 20px;
                bottom: 20px;
                width: calc(100% - 40px);
                display: flex;
                justify-content: space-between;
                .pagination{
                display: flex;
                 >span{
                    line-height: 32px;
                }
            }
            }


        }
    }
}
</style>