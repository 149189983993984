<template>
    <div class="org-tree">
        <div class="sidebar-container">
            <div class="header">
                <span class="title">组织结构</span>
                <span class="expand" @click="expandClick">{{ expandTitle }}</span>
            </div>
            <div class="search">
                <el-input v-model="keyword" size="small" placeholder="请输入关键字"></el-input>
            </div>
            <div class="tree-container">
                <slot name="person"></slot>
                <EasyTree ref="easyTree" :data="orgList" :filterNode="filterNode" 
                :hasSelect="hasSelect"
                :checkboxValues="checkboxValues" 
                @update:checkboxValues="checkboxValuesChange"
                :value="value" @input="valueInput" 
                ></EasyTree>
            </div>
        </div>
    </div>
</template>

<script>
import EasyTree from '@/common/easyTree';

export default {
    components: {
        EasyTree
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        orgList: {
            type: Array,
            default(){
                return [];
            }
        },
        checkboxValues: Array,
        hasSelect: {
            type: Boolean,
            default: false
        },
		onlyShowCourt: {
			type: Boolean,
            default: false
		}
    },
	provide() {
		return {
			onlyShowCourt: this.onlyShowCourt
		}
	},
    data(){
        return {
            keyword: '',
            isExpand: false,
        }
    },
    computed: {
        expandTitle(){
            return this.isExpand ? '收起全部' : '展开全部'
        }
    },
    watch: {
        orgList: {
            handler(val){
                this.$nextTick(() => {
                    this.expandClick();
                })
            }
        },
        keyword: {
            handler (val) {
                this.$nextTick(() => {
                    this.$refs.easyTree.filter(val);
                })
            },
            immediate: true
        },
    },
    methods: {
        expandClick(){
            if(!this.isExpand){
                this.$refs.easyTree.expandAll();
                this.isExpand = !this.isExpand;
            }else{
                this.$refs.easyTree.retractAll()
                this.isExpand = !this.isExpand;
            }
        },
        valueInput(val){
            this.$emit('input',val);
        },
        filterNode (val, data) {
            return data.name.includes(val);
        },
        checkboxValuesChange(val){
            this.$emit('update:checkboxValues',val)
        }
    },
}
</script>

<style lang="less" scoped>
.org-tree{
    height: 100%;
    .sidebar-container {
        width: 250px;
        height: 100%;
        margin-right: 10px;
        background-color: #fff;
        padding: 15px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow: auto;
        .header {
            display: flex;
            justify-content: space-between;

            .title {
                color: #0F0F0F;
            }

            .expand {
                color: #666;
                font-size: 12px;
                cursor: pointer;
            }
        }

        .search {
            margin: 10px 0;
        }

        .tree-container {
            height: 0;
            flex-grow: 1;

            .all {
                height: 34px;
                padding-left: 19px;
                line-height: 34px;
                cursor: pointer;
            }

            .all:hover {
                background-color: #f5f7fa;
            }

            .all.active {
                background-color: rgba(0, 255, 178, 0.15);
            }
        }
    }
}
</style>