<template>
    <div>
        <TreeItem v-for="(item,index) in data" :key="index" :treeItem="item" :level="1" @selectChange="selectChange"></TreeItem>
    </div>
</template>

<script>
import TreeItem from './TreeItem.vue';

export default {
    components: {
        TreeItem
    },
    provide(){
        return {
            easyTree: this
        }
    },
    props: {
        data: {
            type: Array,
            default(){
                return []
            }
        },
        configs: {
            type: Object,
            default(){
                return {};
            }
        },
        filterNode: {
            type: Function,
            default(){
                return () => {};
            }
        },
        hasSelect: {
            type: Boolean,
            default: false
        },
        checkboxValues:{
            type: Array,
            default(){
                return [];
            }
        },
        value: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            selectList: [],
        }
    },
    watch: {
        checkboxValues: {
            handler(val){
                this.$nextTick(() => {
                    this.initCheckboxValues();
                })
            },
            immediate: true
        }
    },
    methods: {
        recursion(node,call){   //  起始节点
            node.$children.forEach(item => {
                // console.log(item.$options.name);
                if(item.$options.name != 'TreeItem') return;
                call(item,node);    //  当前节点，父节点
                item.$children.length ? this.recursion(item,call) : '';
            });
        },
        filter(val){
            this.recursion(this,item => {
                item.visible = this.filterNode(val,item.treeItem);
                if(item.visible){
                    let parent = item.$parent;
                    while(parent.$options.name === 'TreeItem'){
                        parent.visible = true;
                        parent.expand = true;
                        parent = parent.$parent;
                    }
                }
                
            })
        },
        expandAll(){
            this.recursion(this,item => {
                item.expand = true;
            })
        },
        retractAll(){
            this.recursion(this,item => {
                item.expand = false;
            })
        },
        selectChange(val){      //  选择项触发事件
            // const index = this.checkboxValues.indexOf(val);
            // if(index === -1){
            //     this.checkboxValues.push(val);
            // }else{
            //     this.checkboxValues.splice(index,1);
            // }

            const checkboxValues = [];
            this.recursion(this,item => {
                if(item.selected){
                    checkboxValues.push(item.treeItem.id);
                }
            })
            this.$emit('update:checkboxValues',checkboxValues);
        },
        initCheckboxValues(){
            this.recursion(this,item => {
                if(this.checkboxValues.includes(item.treeItem.id)){
                    item.selected = true;
                }else{
                    item.selected = false;
                }
            })
        }
    },
}
</script>

<style lang="less" scoped>

</style>