<template>
    <div class="group" v-show="visible">
        <div class="group-item" :class="{'active': easyTree.value === treeItem.id}" :style="{'padding-left': (level - 1) * 18 + 'px'}" @click="itemClick" :title="treeItem.name">
            <span class="icon" @click.stop="expandClick">
                <i :class="{'expand': expand}" v-if="showIcon" class="iconfont icon-xiala1" ></i>
            </span>          

            <span class="select" @click.stop="selectClick" v-if="easyTree.hasSelect">
                <i :class="{'active': selected}"></i>
            </span>
            <img class="mr5" src="@/assets/dir2.png" alt="" v-if="treeItem.type==='DEPT'">
            <img class="mr5" src="@/assets/court.png" alt="" v-else>
            <span class="text">
                {{treeItem.name}}
                </span>
            <span class="num" v-if="treeItem.count">{{`(${treeItem.count})人`}}</span>
        </div>
        <div v-if="treeItem && treeItem.children" v-show="expand">
            <div v-for="(item,index) in treeItem.children" :key="index">
                <TreeItem :treeItem="item" :level="level + 1" @selectChange="selectChange"></TreeItem>
            </div>

        </div>
    </div>
</template>

<script>
import { traverse } from '@/utils/handle.js';

export default {
    name: 'TreeItem',
    components: {
        
    },
    props: {
        treeItem: Object,
        level: Number,
    },
    inject: ['easyTree', 'onlyShowCourt'],
    data(){
        return {
            expand: false,
            // visible: true,
            selected: false,
            typeList: [
                
            ]
        }
    },
	computed: {
		visible() {
			return this.onlyShowCourt ? this.treeItem.type !== 'DEPT' : true
		},
		showIcon() {
			if(this.onlyShowCourt) {
				return this.treeItem.children && !!this.treeItem.children.filter(child => child.type !== 'DEPT')?.length
			} else {
				return this.treeItem.children && !!this.treeItem.children.length
			}
		}
	},
    methods: {
        expandClick(){
            this.expand = !this.expand;
        },
        itemClick(){
            this.easyTree.$emit('input',this.treeItem.id);
        },
        selectClick(){
            this.selected = !this.selected;

            traverse(this.$children,el => {
                el.selected = this.selected;
            },'$children')
            this.$emit('selectChange')
        },
        selectChange(){
            // console.log(this.$children);
            if(this.$children.find(item => !item.selected)){
                this.selected = false;
            }else{
                this.selected = true;
            }
            this.$emit('selectChange')
        }
    },
}
</script>

<style lang="less" scoped>
.group{
    .group-item{
        height: 34px;
        line-height: 34px;
        display: flex;
        align-items: center;
        .icon{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            padding: 5px;
            flex-shrink: 0;
            i{
                transform: rotate(-90deg);
                color: #999;
            }
            .expand{
                transform: rotate(0);
            }
        }
        .select{
            display: inline-block;
            width: 16px;
            margin-right: 5px;
            display: inline-flex;
            align-items: center;
            i{
                display: inline-block;
                position: relative;
                border: 1px solid #dcdfe6;
                border-radius: 2px;
                box-sizing: border-box;
                width: 14px;
                height: 14px;
                background-color: #fff;
                z-index: 1;
            }
            i.active{
                // easyTree.selectList
                background-color: #409eff;
                border-color: #409eff;
            }
            i::before{
                display: inline-block;
                box-sizing: content-box;
                content: "";
                border: 1px solid #fff;
                border-left: 0;
                border-top: 0;
                width: 3px;
                height: 7px;
                position: absolute;
                left: 4px;
                top: 1px;
                transform: rotate(45deg);
                transform-origin: center;
            }
        }
        .text{
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .group-item:hover{
        background-color: #f5f7fa;
        cursor: pointer;
    } 
    .group-item.active{
        background-color: rgba(0, 255, 178, 0.15);
        cursor: pointer;
    } 
}
</style>